export const LANGUAGE = {
  EN: 'en',
  ZH_CN: 'zh-CN',
  ZH_TW: 'zh-TW',
  KO: 'ko',
}

export const TABLE_TYPES = {
  BARCCART: 'barccart',
  MARBLE: 'marble',
}

export const AVAILABLE_TEAMS = [
  'China',
  'Vietnam',
  'Thailand',
  'Japan',
  'Korea',
  'USA',
  'Canada',
  'Brazil',
  'Argentina',
  'Indonesia',
  'India',
  'Australia',
  'Israel',
  'Palestine',
  'Ukraine',
  'Russia',
  'Ho Chi Minh',
  'Hanoi',
  'Hai Phong',
  'Can Tho',
  'Da Nang',
  'Bien Hoa',
  'Thuan An',
  'Hai Duong',
  'Di An',
  'Thanh Hoa',
  'Nha Trang',
  'Nam Dinh',
  'Hue',
  'Vung Tau',
  'Thai Nguyen',
  'Quy Nhon',
]
